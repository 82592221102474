
export default function Post() {
    return (
        <svg id="Camada_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 26.4 23.88">
            <defs>
                <style>{`
                    .cls-1 {
                        clip-path: url(#clippath-1);
                    }

                    .cls-2 {
                        stroke: #b17f49;
                        stroke-miterlimit: 8;
                    }

                    .cls-2, .cls-3 {
                        fill: none;
                    }

                    .cls-3 {
                        clip-rule: evenodd;
                    }

                    .cls-4 {
                        fill: #b17f49;
                        fill-rule: evenodd;
                    }

                    .cls-5 {
                        clip-path: url(#clippath);
                    }
                `}
                </style>
                <clipPath id="clippath">
                    <polygon className="cls-3" points="0 23.88 26.4 23.88 26.4 0 0 0 0 23.88 0 23.88" />
                </clipPath>
                <clipPath id="clippath-1">
                    <polygon className="cls-3" points="0 23.88 26.4 23.88 26.4 0 0 0 0 23.88 0 23.88" />
                </clipPath>
            </defs>
            <g id="Camada_1-2" data-name="Camada_1">
                <g>
                    <path className="cls-4" d="M3.56,19.34h12.47l6.43,4.3c.12.08.25.12.38.12.11,0,.22-.03.32-.08.23-.12.36-.35.36-.61v-4.07c1.25-.53,2.76-1.67,2.76-3.2V3.54c0-1.95-1.59-3.54-3.56-3.54H3.55C1.59,0,0,1.59,0,3.54v12.26c0,1.95,1.59,3.54,3.55,3.54h0ZM1.37,3.55c0-1.2.97-2.18,2.18-2.18h19.18c1.2,0,2.18.98,2.18,2.18v12.26c0,.8-1.2,1.73-2.28,2.08-.28.09-.48.35-.48.65v3.27l-5.54-3.7c-.11-.07-.25-.12-.38-.12H3.55c-1.2,0-2.18-.98-2.18-2.18V3.55h0Z" />
                    <path className="cls-2" d="M6,9.84c0-.73.59-1.32,1.32-1.32s1.32.59,1.32,1.32-.59,1.32-1.32,1.32-1.32-.59-1.32-1.32h0Z" />
                    <g className="cls-5">
                        <path className="cls-2" d="M11.76,9.84c0-.73.62-1.32,1.38-1.32s1.38.59,1.38,1.32-.62,1.32-1.38,1.32-1.38-.59-1.38-1.32h0Z" />
                        <g className="cls-1">
                            <path className="cls-2" d="M17.64,9.84c0-.73.59-1.32,1.32-1.32s1.32.59,1.32,1.32-.59,1.32-1.32,1.32-1.32-.59-1.32-1.32h0Z" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

