import * as React from 'react';
import { TESTIMONY } from '../../functions/Queries';
import { Button, Flex, Modal, Tabs } from 'antd';
import TextStyle from './TextStyle';
import { PSQData } from './questions/PSQData';
import { StoryData } from './questions/StoryData';
import Testimony from './Testimony';

export default function Source(props:{
  onDataChanged:(val:any)=>void,
  data?:any,
  testimony?:TESTIMONY[],
  onTestimonyChanged:(val:TESTIMONY[])=>void,
  open:boolean,
  onClose:()=>void
}) {

  const [selectedMethod, setSelectedMethod] = React.useState('noticia')
  const [data,setData] =React.useState<any>([])

  const onDataChanged = (val:any) => {
    props.onDataChanged(val)
  }

  React.useEffect(()=>{
    if(props.data){
      setData(structuredClone(props.data))
    }
  },[props.data])

  const onCancel = () => {
    if(props.data){
      setData(structuredClone(props.data))
    }
    props.onClose()
  }

  const methodsInput = {
        noticia: (<PSQData data={data?.name === "PSQ" ? data:undefined} onDataChanged={onDataChanged}/>),
        historia:(<StoryData data={data?.name !== "PSQ" ? data:undefined} onDataChanged={onDataChanged}/>)
  }
  
  const footer = (
    <Button key="1" onClick={props.onClose} style={{padding:'0.1rem 0.3rem 0.1rem 0.3rem',marginBottom:'0.3rem', marginRight:'0.3rem'}} >Salvar</Button>
  )

  const informacoes = (
            <Flex vertical className="info-panel-content" align='center' gap="large">
              <TextStyle options="source" value={selectedMethod} onChange={setSelectedMethod}/>
              {(methodsInput as any)[selectedMethod]}
            </Flex>
  )

  const depoimentos = (
    <div className="info-panel-content">
      <Testimony onDataChanged={props.onTestimonyChanged} data={props.testimony}/> 
    </div>
  )

  const tabItems = [{
    key: '1',
    label: 'INFORMAÇÕES',
    children: informacoes
  },
  {
    key: '2',
    label: 'DEPOIMENTOS',
    children: depoimentos
  }]
  return (
    <Modal open={props.open} onCancel={onCancel} className='source-modal' style={{ top: 20 }} footer={[footer]}>
      <div className="info-panel">
        <Tabs defaultActiveKey="1" items={tabItems}/>
      </div>
    </Modal>
  )

}