import { Avatar, Button, Divider, Flex, Layout } from 'antd';
import './App.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import { UserOutlined } from '@ant-design/icons';
import ActionButton from './components/ActionButton';
import { Outlet, useNavigate } from 'react-router-dom';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60000,
    }
  }
});



function App() {
  const navigate = useNavigate()
  
  const navigateTo = () => {
      navigate("/app/dashboard")
  }
  return (
    <QueryClientProvider client={queryClient}>
      <Layout >
        <Header className='header'>
          <Flex justify="space-between" align="center" style={{width:'100%'}}>
            <img className='logo' src="/logo2.png" width="100%" height='100%' />
            <Flex align='center' >
              <ActionButton onClick={navigateTo}>Dashboard</ActionButton>      
              <Avatar className="userMenu" size={48} icon={<UserOutlined />}  />
            </Flex>
          </Flex>
        </Header>
        <Content className='content'><Outlet/></Content>
        <Footer className="footer">
           <Divider />
            © Journo | Inteligência em Conteúdos para Comunicação Interna 2024            
        </Footer>
      </Layout>
    </QueryClientProvider>);
}

export default App;
