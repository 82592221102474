import { useState } from "react";
import { QuestionComponent } from "./QuestionComponent";

const questions = [
  "Qual é o tema?",
  "Qual é o objetivo dessa comunicação? A quem ela é dirigida?",
  "Qual é a relação da história do(s) personagem(ns) com o assunto que será divulgado? De que forma essa história irá inspirar/impactar as outras pessoas?",
  "Quem é ou quem são os personagens principais dessa história: nome, cargo, em que unidade trabalha e outras informações que possam gerar valor na história, como tempo de empresa, em que cargo começou, entre outros que julgar relevante.",
  "Qual foi o desafio, imprevisto ou problema enfrentado pelo(s) personagem(ns)?",
  "Quais foram as iniciativas e posturas adotadas pelo(s) personagem(ns) para enfrentar a situação?",
  "A empresa teve alguma contribuição para a solução do desafio?",
  "Quais foram os resultados alcançados após isso?",
  "E como ficou o novo contexto do(s) personagem(ns) após a solução do desafio?",
  "Que palavras-chave resumem a mensagem que você quer passar com essa história?",
  "Há informações complementares que considera relevante incluir na história?",
]

export function StoryData(props: {
  onDataChanged: (val: any | null) => void
  data?: any
}) {
  const [questionValues, setQuestionValues] = useState(props.data ? props.data["story"] : questions.map(() => ''))

  const buildData = () => {
    return {
      name: 'História',
      historia: questionValues,
    }
  };

  const validateState = () => {
    for (var stateIdx = 0; stateIdx < questionValues.length; stateIdx++) {
      if (questionValues[stateIdx] === '')
        return false;
    }
    return true;
  }

  const handleValuesChange = (newValues:string[]) => {
    setQuestionValues(newValues);
    props.onDataChanged(validateState() && buildData())
  }

  return (<QuestionComponent handleValuesChange={handleValuesChange} questions={questions} data={questionValues} />

  )
}

