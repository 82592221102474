import env from "./env"
import { doFetch } from "./CognitoClient"

export type TEXT_INFO = {
    id:string,
    name:string,
    created:string,
    lastModified:string,
    type: string
}
export type TESTIMONY = {
    name: string,
    position: string,
    statement: string
  }
  

export type TEXT_CONTENT = {
    content:{
        title:string,
        content:string
    },
    info: {
        name : string,
        voice : string,
        style?: string,
        type: string,
        data : any,
        testimonies?: TESTIMONY[]
    },
    id: string,
    prompts?: {role:string,content:string}[]
}

type FUNCTION_CONFIG_ITEM = {
    method: "GET"|"PUT"|"POST"|"DELETE"
}

const FUNCTION_TYPES = {
    ALL_TEXTS : ({
        method: "GET"
    } as FUNCTION_CONFIG_ITEM),
    TEXT : ({
        method: "GET"
    } as FUNCTION_CONFIG_ITEM),
    UPDATE_TEXT_CONTENT : ({
        method: "PUT"
    } as FUNCTION_CONFIG_ITEM),
    CREATE_TEXT : ({
        method: "POST"
    } as FUNCTION_CONFIG_ITEM),
    DELETE_TEXT : ({
        method: "DELETE"
    } as FUNCTION_CONFIG_ITEM)

}

async function getFetchOptions(o:FUNCTION_CONFIG_ITEM): Promise<RequestInit> {
    const headers = new Headers();
    if(o.method !== 'GET') {
        headers.append("Accept",'application/json')
        headers.append("Content-Type",'application/json')
    }
    return {
        method:o.method,
        mode:'cors',
        headers: headers
    }        
}


async function fetchText(id:string): Promise<TEXT_CONTENT|undefined> {
    const options = await getFetchOptions(FUNCTION_TYPES.TEXT)
    var retries = 1;
    await delay(3000);
    while(true) {
        env.log.info(`tentativa ${retries} de obtenção do texto ${id}`)
        const response = await doFetch(`${env.NEXA_API}/text/${id}`, options);
        if(response.status==202){
            retries++
            await delay(3000);
        }
        else if(response.status==200) {
            const data = await response.json()
            env.log.info(`obtido ${id} após ${retries} tentativas`, response.status, data)
            return data
        }
        else {
            env.log.info(`erro na tentativa ${retries} do documento ${id}: ${response.status}`,response.statusText)
            return undefined
        }
    }
}

function delay(milliseconds: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, milliseconds);
    });
}

export default {
    queries: {
        ALL_TEXTS :{
            queryKey : ['ALL_TEXTS'],
            queryFn: async (): Promise<TEXT_INFO[]> => {
                try {
                    const options = await getFetchOptions(FUNCTION_TYPES.ALL_TEXTS)
                    const response = await doFetch(`${env.NEXA_API}/text`,options);
                    const data = await response.json()
                    return data
                }
                catch (e) {
                    console.error('erro', e)
                    return []
                }
            }
        },
        TEXT: (id:string,type:string) => ({
            queryKey: ['TEXT',type,id],
            queryFn: async (): Promise<TEXT_CONTENT|undefined> => {
                const data = await fetchText(id);
                return data
            }
        }),        
    },
    mutations: {
        UPDATE_TEXT_CONTENT: {
            mutationFn: async (data:{id:string, type:string, title:string, content:string}) => {
                const options = await getFetchOptions(FUNCTION_TYPES.UPDATE_TEXT_CONTENT)
                const result = await doFetch(`${env.NEXA_API}/text/${data.id}`,{
                    ...options,
                    body:JSON.stringify({title:data.title,content:data.content})
                })
                return result.status === 200;
            }
        },
        CREATE_TEXT: {
            mutationFn: async (data:{id?:string, data: {name:string, voice:string, style:string, type:string, data:any,testimonies:TESTIMONY[]}}) => {
                const sufix = data.id ? `/${data.id}` : ''
                const options = await getFetchOptions(FUNCTION_TYPES.CREATE_TEXT)
                const result = await doFetch(`${env.NEXA_API}/text${sufix}`,{
                    ...options,
                    body:JSON.stringify(data.data)
                })
                if(result.status === 200) {
                    const res = await result.json()
                    const id = res.id as string
                    return await fetchText(id)
                }
                return undefined;
            }
        },
        DELETE_TEXT: {
            mutationFn: async (data:{id:string}) => {
                const options = await getFetchOptions(FUNCTION_TYPES.DELETE_TEXT)
                const result = await doFetch(`${env.NEXA_API}/text/${data.id}`,{
                    ...options,
                })
                return result.status === 200;
            }
        },
    }
}