import { Divider, Flex, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import './questions.css'

export function QuestionComponent(props: {
  questions: string[],
  data?: string[],
  order?: number[],
  optional?: number[],
  handleValuesChange: (newValues: string[]) => void

}) {
  const [questionValues, setQuestionValues] = useState(props.questions.map(() => ''))

  useEffect(() => {
    if(props.data){
      setQuestionValues(props.data)
    }
  },[props.data])

  const handleQuestionChange = (e: any,idx:number) => {
    questionValues[idx] = e.target.value;
    const newValues = [...questionValues];
    setQuestionValues(newValues);
    props.handleValuesChange(newValues)
  }

  const theOrder = props.order || props.questions.map((x,idx) => idx)

  return (
    <Flex vertical className="create-text-section-panel" gap="small">
      {
        theOrder.map((x,idx) => (
          <div key={idx}>
          <Flex vertical className="create-text-section-item" >
            <span>{props.questions[x]}{props.optional && props.optional.includes(x) ? "" : "*"}</span>
            <TextArea rows={4} onChange={(e)=>handleQuestionChange(e,x)} value={questionValues[x]} />
          </Flex>
          {idx < theOrder.length - 1 && (<Divider />)}
          </div>
        ))
      }
    </Flex>

  )
  // return (
  //   <Box onClick={(e) => { e.stopPropagation() }} sx={{ overflowY:'auto', flex:1 }} p={1} m={1} height='100%'>
  //     {
  //       theOrder.map((x) => (
  //         <BorderedSection>
  //           <p>{props.questions[x]}</p>
  //           <TextField variant="outlined" fullWidth multiline rows={4} onChange={(e)=>handleQuestionChange(e,x)} value={questionValues[x]} />
  //         </BorderedSection>
  //       ))
  //     }
  //   </Box>

  // )
}

