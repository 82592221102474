export default function Email() {
    return (
        <svg id="Camada_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 26.52 24.12">
            <defs>
                <style>{`
                    .cls-1 {
                        stroke: #b17f49;
                        stroke-linecap: round;
                        stroke-miterlimit: 8;
                    }

                    .cls-1, .cls-2 {
                        fill: none;
                    }

                    .cls-2 {
                        clip-rule: evenodd;
                    }

                    .cls-3 {
                        fill: #b17f49;
                        fill-rule: evenodd;
                    }

                    .cls-4 {
                        clip-path: url(#clippath);
                    }
                `}</style>
                <clipPath id="clippath">
                    <polygon className="cls-2" points="0 24.12 26.52 24.12 26.52 0 0 0 0 24.12 0 24.12" />
                </clipPath>
            </defs>
            <g id="Camada_1-2" data-name="Camada_1">
                <g>
                    <path className="cls-3" d="M26.3,9.4l-4.35-3.18v-.76c0-1.53-1.3-2.79-2.9-2.79h-1.97L13.57.1c-.18-.13-.44-.13-.62,0l-3.52,2.57h-1.97c-1.59,0-2.9,1.25-2.9,2.79v.76L.22,9.4c-.13.1-.22.26-.22.42,0,.03,0,.06,0,.09,0,.02,0,.05,0,.07v13.17c0,.07.02.15.05.22-.05.12-.07.26-.02.39.07.22.27.36.5.36h25.47c.22,0,.43-.14.5-.36.04-.13.03-.27-.02-.38.03-.07.05-.14.05-.22v-13.17s0-.05,0-.07c0-.03,0-.06,0-.09,0-.17-.08-.32-.22-.42v-.02ZM21.95,7.54l3.16,2.31-3.05,2.35-.12.08v-4.73h0ZM13.26,1.19l2.04,1.48h-4.07l2.03-1.48h0ZM5.62,5.46c0-.95.82-1.72,1.83-1.72h11.59c1.01,0,1.83.77,1.83,1.72v7.59l-4.16,3.02c-.07.02-.14.04-.2.09l-3.24,2.45-3.39-2.45h0l-4.27-3.1v-7.59h0ZM4.57,7.54v4.63l-3.15-2.34,3.15-2.31h0ZM1.05,11.03l7.64,5.55-7.64,5.55v-11.09h0ZM2.07,23.06l7.53-5.83,3.38,2.45c.19.13.44.13.63,0l3.23-2.44,7.59,5.82H2.07ZM25.47,22.11l-7.64-5.55,7.64-5.55v11.09h0Z" />
                    <line className="cls-1" x1="7.8" y1="7.56" x2="18.57" y2="7.56" />
                    <g className="cls-4">
                        <line className="cls-1" x1="7.8" y1="11.16" x2="18.57" y2="11.16" />
                    </g>
                </g>
            </g>
        </svg>
    )
}

