import { useRef } from "react";
import { Divider, Flex, Segmented, Select, Tour, TourProps } from "antd";
import './createText.css';
import { SegmentedOptions, SegmentedProps, SegmentedValue } from "antd/es/segmented";
import { ToneTypes, VoiceTypes } from "../../functions/VoiceFunctions";
import { Book, Newspaper } from "../../components/icons";
import { DefaultOptionType } from "antd/es/select";

export default function TextStyle(props:{
  open?:boolean,
  onClose?:()=>void,
  options: "voice"|"tone"|"source",
  value?:string,
  onChange?:(val:string)=>void
}){
  const refs = [useRef(null),useRef(null),useRef(null),]

  const SourceTypes = [
    {
      name: 'Notícia',
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque fringilla commodo tempus. Quisque et pharetra sapien. Aliquam ut cursus velit, et scelerisque enim. Donec nec pellentesque sem. Phasellus eget velit magna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque condimentum fringilla sapien at tempus. Etiam aliquam orci vel tristique imperdiet. Ut ornare ex ac ullamcorper malesuada.`,
      id: 'noticia',
      icon: (<Newspaper />),
    },
    {
      name: 'História',
      description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque fringilla commodo tempus. Quisque et pharetra sapien. Aliquam ut cursus velit, et scelerisque enim. Donec nec pellentesque sem. Phasellus eget velit magna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque condimentum fringilla sapien at tempus. Etiam aliquam orci vel tristique imperdiet. Ut ornare ex ac ullamcorper malesuada.`,
      id: 'historia',
      icon: (<Book />),
    },
  ]

  const data = {
    voice: VoiceTypes,
    tone: ToneTypes,
    source: SourceTypes
  }[props.options]
  
  if(props.options === 'source'){	
  const options:SegmentedOptions<string> = data.map((e,idx) => ({
    label: (
      <Flex className="create-text-text-style-button" align="center" gap={8} ref={refs[idx]}>
        {e.icon}
        <span >{e.name}</span>  
      </Flex>
    ),
    value: e.id
  }))

  const steps: TourProps['steps'] = data.map((e,idx) => ({
    title: e.name,
    description: e.description,
    target: refs[idx].current,
    nextButtonProps: {children:'Próximo'},
    prevButtonProps: {children:'Anterior'},
  }))

  return (
    <>
      <Segmented options={options} value={props.value} onChange={props.onChange} />
      {props.open !== undefined && <Tour open={props.open} onClose={props.onClose} steps={steps}/>}
    </>
  )
}
else {
  const options:DefaultOptionType[] = data.map((e) => ({
    label: e.name,
    value: e.id
  }))

  return (
    <div className="text-style-select">
      <Select options={options} value={props.value} onChange={props.onChange} />
    </div>
  )
}
}

