export default function Newspaper() {
    return (
        <svg id="Camada_2" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 22.08 24.12">
            <defs>
                <style>{`
                    .cls-1 {
                        clip-path: url(#clippath-4);
                    }

                    .cls-2 {
                        stroke-linecap: round;
                    }

                    .cls-2, .cls-3 {
                        stroke: #b17f49;
                    stroke-miterlimit: 8;
                    }

                    .cls-2, .cls-3, .cls-4 {
                        fill: none;
                    }

                    .cls-5 {
                        clip-path: url(#clippath-1);
                    }

                    .cls-6 {
                        clip-path: url(#clippath-3);
                    }

                    .cls-4 {
                        clip-rule: evenodd;
                    }

                    .cls-7 {
                        clip-path: url(#clippath-2);
                    }

                    .cls-8 {
                        fill: #b17f49;
                        fill-rule: evenodd;
                    }

                    .cls-9 {
                        clip-path: url(#clippath);
                    }
                `}</style>
                <clipPath id="clippath">
                    <polygon className="cls-4" points="0 24.12 22.08 24.12 22.08 0 0 0 0 24.12 0 24.12" />
                </clipPath>
                <clipPath id="clippath-1">
                    <polygon className="cls-4" points="0 24.12 22.08 24.12 22.08 0 0 0 0 24.12 0 24.12" />
                </clipPath>
                <clipPath id="clippath-2">
                    <polygon className="cls-4" points="0 24.12 22.08 24.12 22.08 0 0 0 0 24.12 0 24.12" />
                </clipPath>
                <clipPath id="clippath-3">
                    <polygon className="cls-4" points="0 24.12 22.08 24.12 22.08 0 0 0 0 24.12 0 24.12" />
                </clipPath>
                <clipPath id="clippath-4">
                    <polygon className="cls-4" points="0 24.12 22.08 24.12 22.08 0 0 0 0 24.12 0 24.12" />
                </clipPath>
            </defs>
            <g id="Camada_1-2" data-name="Camada_1">
                <g>
                    <path className="cls-8" d="M.42,0H.42c-.11,0-.22.04-.3.12-.08.08-.12.18-.12.3v23.23c0,.23.19.42.42.42l21.11.04h0c.11,0,.22-.04.3-.12.08-.08.12-.18.12-.3V.46c0-.23-.19-.42-.42-.42L.42,0H.42ZM21.11,23.28l-20.27-.04V.84l20.27.04v22.39h0Z" />
                    <rect className="cls-3" x="3.6" y="5.04" width="6.36" height="7.56" rx="1.06" ry="1.06" />
                    <g className="cls-9">
                        <line className="cls-2" x1="3.6" y1="19.08" x2="18.41" y2="19.08" />
                        <g className="cls-5">
                            <line className="cls-2" x1="3.6" y1="15.72" x2="18.41" y2="15.72" />
                            <g className="cls-7">
                                <line className="cls-2" x1="12.48" y1="12.24" x2="18.41" y2="12.24" />
                                <g className="cls-6">
                                    <line className="cls-2" x1="12.48" y1="8.88" x2="18.41" y2="8.88" />
                                    <g className="cls-1">
                                        <line className="cls-2" x1="12.48" y1="5.52" x2="18.41" y2="5.52" />
                                    </g>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

