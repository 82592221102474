import { useEffect, useState } from "react";
import { QuestionComponent } from "./QuestionComponent";


const PSQQuestions = [
  {
    name: "Geral",
    questions: [
      "Qual é o assunto da notícia?",
      "Que tipo de resultado você busca com a divulgação dessa notícia?",
      "Que palavras-chave resumem a mensagem a ser transmitida?",
      "Informações adicionais que considera importante serem incluídas no conteúdo (opcional)",
    ]
  },
  {
    name: "Pensar",
    questions: [
      "Por que esta notícia é importante para a empresa? A qual objetivo estratégico ela está vinculada?",
      "Quando este fato ou iniciativa acontecerá? (caso não seja necessário divulgar alguma data, favor pular para a próxima pergunta)",
      "Quais são as principais informações que precisam ser comunicadas aos colaboradores?",
    ]
  },
  {
    name: "Sentir",
    questions: [
      "Por que esta notícia é importante para os colaboradores?",
      "Os colaboradores têm alguma opinião ou preocupação sobre essa questão? Se sim, quais são elas?",
      "Como você gostaria que os colaboradores se sentissem após ler este conteúdo?"
    ]
  },
  {
    name: "Querer",
    questions: [
      "Se houver, quais são os próximos passos previstos? Favor detalhar os prazos",
      "Há alguma ação que os leitores precisam fazer a partir dessa comunicação?",
    ]
  },
]

const questions = [...PSQQuestions[0].questions,...PSQQuestions[1].questions,...PSQQuestions[2].questions,...PSQQuestions[3].questions]
const order = [0,1,6,5,4,7,8,11,10,2,9,3]
const optional = [5,4,7,8,11,10,2,9,3]

export function PSQData(props:{
  onDataChanged:(val:any|null)=>void
  data?: any
}) {
  const [questionValues, setQuestionValues] = useState<string[]>(questions.map(() => ''))
    
  useEffect(() => {
    if(props.data){
      setQuestionValues([...props.data["geral"],...props.data["pensar"],...props.data["sentir"],...props.data["querer"],...props.data["geral"]])
    }
  },[props.data])

  const buildData = () => {
    return {
      name: 'PSQ',
      geral : [...questionValues.slice(0,4)],
      pensar: questionValues.slice(4,7),
      sentir:questionValues.slice(7,10),
      querer:questionValues.slice(10,12)
    }
  };

  const validateState = () => {
    for(var answerIdx = 0; answerIdx < questionValues.length; answerIdx++){
      if(questionValues[answerIdx] === '' && !optional.find(x=> x===answerIdx)) 
        return false;
    }
    return true;
  }

  const handleValuesChange = (newValues:string[]) => {
    setQuestionValues(newValues);
    props.onDataChanged(validateState() && buildData())
  }

  return (<QuestionComponent handleValuesChange={handleValuesChange} questions={questions} data={questionValues} order={order} optional={optional}/>)

}

