import ListText from '../../components/ListText';
import { Button, Collapse, CollapseProps, Flex } from 'antd';
import { TextType, TextTypes } from '../../functions/TextFunctions';
import './dashboard.css';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
  const navigate = useNavigate()
  
  const navigateTo = (type:TextType) => {
        navigate("/app/createContent",{state:type.params})
  }

  return (

    <Flex vertical>
      <Flex justify='center' gap="large" >
        {TextTypes.map(el => (
          <Button key={el.shortTitle} className='button-create' icon={el.icon} iconPosition='start' onClick={()=>navigateTo(el)}>
        {el.shortTitle} 
          </Button>
        ))}
      </Flex>
      <div className='dashboard-leftArea'>
        <ListText/>
      </div>
    </Flex>

  );
}

export default Dashboard;
