import * as React from 'react';
import ActionButton from '../../components/ActionButton';
import { Button, Descriptions, Flex } from 'antd';
import { TESTIMONY } from '../../functions/Queries';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';


export default function Testimony(props:{
  onDataChanged:(val:TESTIMONY[])=>void,
  data?:TESTIMONY[]
}) {
  const [testimonies, setTestimonies] = React.useState(props.data || [])
  const [idxEdit, setIdxEdit] = React.useState<number|undefined>(undefined)

  const onClose = (val?:{testimony:TESTIMONY,idx:number})=> {
    if(val)
    {
       if(val.idx>=0) testimonies[val.idx] = val.testimony;
       else testimonies.push(val.testimony)

       setTestimonies([...testimonies])
       props.onDataChanged(testimonies)
    }
    setIdxEdit(undefined)
  }
  
  const onDelete = (idx:number)=>{
    testimonies.splice(idx,1)
    setTestimonies([...testimonies])
    props.onDataChanged(testimonies)
  }

  const onEdit = (idx:number)=>{
    setIdxEdit(idx)
  }

  return (
    <Flex vertical align='center' gap='middle'>
      <div>
        <ActionButton>NOVO DEPOIMENTO</ActionButton>
      </div>
      { testimonies.map((e,idx) => {
        const title = (<Flex gap={4}>{e.name} <Button shape='circle' icon={<EditOutlined />} size="small" /> <Button shape='circle' size="small" icon={<DeleteOutlined />}/> </Flex>)
        return (
          <Descriptions title={title} bordered layout='vertical' className='create-text-testimony-card'>
            <Descriptions.Item label="Cargo">{e.position}</Descriptions.Item>
            <Descriptions.Item label="Depoimento">{e.statement}</Descriptions.Item>
          </Descriptions>
        )
      })}
    </Flex>
  )

  //   <Grid container>
  //     <Grid xs={4} xsOffset={5}>
  //       <Button variant="outlined" onClick={()=>setIdxEdit(-1)}>Novo Depoimento</Button>
  //     </Grid>
  //        <Grid xs={12}>
  //           <Box onClick={(e) => { e.stopPropagation() }} sx={{ overflowY:'auto', height:'55vh' }} p={1} m={1} height='100%'>
  //             { testimonies.map((e,idx) => {
  //               return (
  //               <BorderedSection title={e.name} sx={{marginBottom:'1.5rem'}} onEdit={()=>{onEdit(idx)}} onDelete={()=>{onDelete(idx)}}>
  //                 <span style={{marginRight:'1rem',fontWeight:'bold'}}>Cargo:</span><span>{e.position}</span>
  //                 <div style={{width:'100%', height:'1rem'}}></div>
  //                 <span style={{marginRight:'1rem',fontWeight:'bold'}}>Depoimento:</span>
  //                 <Typography variant="subtitle1" >{e.statement}</Typography>
  //               </BorderedSection>
  //               )})
  //             }
  //           </Box>
  //        </Grid>
  //        <TestimonyDialog idx={idxEdit!} onClose={onClose} open={idxEdit!==undefined} data={idxEdit!>=0?testimonies[idxEdit!]:undefined}/>
  //   </Grid>
  // );
}

// function TestimonyDialog(props:{
//   data?:TESTIMONY,
//   idx:number,
//   open:boolean
//   onClose:(val?:{testimony:TESTIMONY,idx:number})=>void
// }) {
//   const [name,setName] = React.useState<string>('')
//   const [position, setPosition] = React.useState<string>('')
//   const [statement,setStatement] = React.useState<string>('')

//   React.useEffect(()=>{
//     setName(props.data?.name || '')
//     setPosition(props.data?.position || '' )
//     setStatement(props.data?.statement || '')
//   },[props])

//   const doClose = ()=>{
//     props.onClose({
//       idx:props.idx,
//       testimony: { name,position,statement }
//     })
//   }

//   const doCancel = ()=>{props.onClose(undefined)}

//   return (
//     <Dialog open={props.open} maxWidth="xl">
//       <DialogContent >
//         <Grid width="50vh" container rowSpacing={3}>
//           <Grid xs={12}>
//             <TextField variant="outlined" fullWidth label="Nome" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setName(event.target.value);}} value={name}/>        
//           </Grid>
//           <Grid xs={12}>
//             <TextField variant="outlined" fullWidth label="Cargo" onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPosition(event.target.value);}} value={position}/>        
//           </Grid>
//           <Grid xs={12}>
//             <TextField variant="outlined" fullWidth label="Depoimento" multiline rows={4} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setStatement(event.target.value);}} value={statement}/>        
//           </Grid>
//         </Grid>
//       </DialogContent>
//       <DialogActions>
//           <Button onClick={doCancel}>Cancelar</Button>
//           <Button disabled={name === '' || position === '' || statement === '' } onClick={doClose}>Confirmar</Button>
//       </DialogActions>
//     </Dialog>
//   )
// }