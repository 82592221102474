import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Queries, { TESTIMONY, TEXT_CONTENT } from "../../functions/Queries";
import { GetTextType, TextTypes } from "../../functions/TextFunctions";
import { Button, Card, Flex, Input, Segmented, Space, Spin, Tabs, Tour, TourProps } from "antd";
import './createText.css';
import QuestionPopup from "../../components/QuestionPopup";
import { SegmentedProps } from "antd/es/segmented";
import { ToneTypes, VoiceTypes } from "../../functions/VoiceFunctions";
import ActionButton from "../../components/ActionButton";
import Source from "./Source";
import TextStyle from "./TextStyle";
import Editor from "./Editor";
import { CopyTextCallbackParams, CopyTextDialog } from "./CopyTextDialog";

export default function CreateText() {

  const {state} = useLocation();

  const [name,setName] = useState('')
  const [voice,setVoice] = useState('')
  const [style,setStyle] = useState('neutro')
  const [data,setData] = useState<any>(false)
  const [testimonies,setTestimonies] = useState<TESTIMONY[]>([])
  const [createEnabled,setCreateEnabled] = useState(false)
  const [waiting,setWaiting] = useState(false)
  const [content,setContent] = useState<any>(undefined)
  const [id,setId] = useState<string>()
  const [type,setType] = useState<string>(state.type)
  const [prompt,setPrompt] = useState<any>()
  const [showPrompts,setShowPrompts] = useState(false)
  const [textType,setTextType] = useState(GetTextType(state.type))


  const [textTypeTourOpen,setTextTypeTourOpen] = useState(false)
  const [voiceTourOpen,setVoiceTourOpen] = useState(false)
  const [briefingOpen,setBriefingOpen] = useState(false)


  const queryClient = useQueryClient();
  
  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event?.target.value)
  }
  
  const assignData = (data:TEXT_CONTENT|undefined) =>{
    if(data){
      setName(data.info.name)
      setVoice(data.info.voice)
      setStyle(data.info.style || 'neutro')
      setData(data.info.data)
      setContent(data.content)
      setId(data.id)
      setType(data.info.type)
      setPrompt(data.prompts)
      setTestimonies(data.info.testimonies||[])
    }
  }
  

  useEffect(()=>{
    const nameIsValid = name !== ''
    const voiceIsValid = voice !== ''
    const dataIsValid = !!data
    setCreateEnabled(nameIsValid && voiceIsValid && dataIsValid)
  },[name,voice,data])

  useEffect(()=>{
    setTextType(GetTextType(type || state.type))
  },[type])

  const newTextMutation = useMutation(Queries.mutations.CREATE_TEXT);
  
  useEffect(() => {
    if(state?.id) {
        setWaiting(true)
        queryClient.fetchQuery(Queries.queries.TEXT(state.id,state.type)).then(data => {
          assignData(data)
          setWaiting(false)        
        }).catch(error => {
          console.error('onEditError',error)
        }).finally(()=>{
          setWaiting(false)
        })
    }
  },[])

  const createNewTextPayload = (value:{id?:string,data:{name:string,voice:string,style:string,type:any,data:any,testimonies:TESTIMONY[]}}) => {
    setWaiting(true)
    newTextMutation.mutate(value,
      {
      onSuccess: (data: any) => {
        assignData(data)
        setWaiting(false);
      },
      onError: (err) => {
        console.error(err)
        setWaiting(false);
      }
    });
  }

  const regenerate = () => {
    createNewTextPayload({id:id,data:{name,voice,style,type,data,testimonies}})
  }

  
  return (
    <>
    <Flex vertical style={{paddingLeft:'1rem',paddingRight:'1rem'}} gap="large" align="center" >
        <Flex justify="space-between" gap="large">
            <div className='create-text-type-label' onClick={()=>{if(prompt){setShowPrompts(true)}}}>
              {textType.title} 
            </div>
            <Card title={(<Flex><span style={{marginRight:'0.25rem'}}>Tema</span><QuestionPopup text="Nome interno para identificação.##Para uso em buscas e listas dentro da ferramenta." /></Flex>)} className="create-text-header-card create-text-header-card-theme"  >
              <Input value={name} onChange={handleNameChange} />
            </Card>
            <Button className="briefing-button" onClick={()=>setBriefingOpen(true)}>Briefing</Button>
            <Card title={(<Flex><span style={{marginRight:'0.25rem'}}>Estilo</span></Flex>)} className="create-text-header-card"  >
                <TextStyle open={textTypeTourOpen} onClose={()=>setTextTypeTourOpen(false)} options="voice" value={voice} onChange={setVoice} />
              </Card>
              <Card title={(<Flex><span style={{marginRight:'0.25rem'}}>Tom de voz</span></Flex>)} className="create-text-header-card"  >
                <TextStyle open={voiceTourOpen} onClose={()=>setVoiceTourOpen(false)} options="tone" value={style} onChange={setStyle}/>
              </Card>
        </Flex>
        <Editor title={content?.title}  text={content?.content} id={id} type={type} onRegenerate={regenerate} canGenerate={createEnabled}/>
    </Flex>
    <Source open={briefingOpen} onClose={()=>{setBriefingOpen(false)}} data={data} onDataChanged={setData} testimony={testimonies} onTestimonyChanged={setTestimonies}/> 
    <Spin spinning={waiting} percent="auto" fullscreen />
    </>
  )
 
}

