import { Bullhorn, Film, Microphone, User, UserNinja, UserTie } from "../components/icons";

export const VoiceTypes = [
  {
    name: 'Padrão',
    description: `Equilibra um sentimento positivo em relação à notícia, com contextos explicativos e chamadas para ações que precisem ser realizadas.`,
    id: 'jornalista',
    icon: (<Microphone />),
  },
  {
    name: 'Entusiasta',
    description: `Amplia os níveis de vibração e entusiasmo em relação à notícia, aumentando a carga emocional nas conquistas ou celebrações relevantes.`,
    id: 'entusiasta',
    icon: (<Bullhorn />),
  },
  {
    name: 'Narrador',
    description: `Inspiracional e positivista. O foco é criar o sentimento de pertencimento no leitor através da contação de histórias`,
    id: 'contadorHistoria',
    icon: (<Film />),

  },
]

export const ToneTypes = [
  {
    name: 'Formal',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque fringilla commodo tempus. Quisque et pharetra sapien. Aliquam ut cursus velit, et scelerisque enim. Donec nec pellentesque sem. Phasellus eget velit magna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque condimentum fringilla sapien at tempus. Etiam aliquam orci vel tristique imperdiet. Ut ornare ex ac ullamcorper malesuada.`,
    id: 'formal',
    icon: (<UserTie />),
  },
  {
    name: 'Neutro',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque fringilla commodo tempus. Quisque et pharetra sapien. Aliquam ut cursus velit, et scelerisque enim. Donec nec pellentesque sem. Phasellus eget velit magna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque condimentum fringilla sapien at tempus. Etiam aliquam orci vel tristique imperdiet. Ut ornare ex ac ullamcorper malesuada.`,
    id: 'neutro',
    icon: (<User />),
  },
  {
    name: 'Informal',
    description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque fringilla commodo tempus. Quisque et pharetra sapien. Aliquam ut cursus velit, et scelerisque enim. Donec nec pellentesque sem. Phasellus eget velit magna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Pellentesque condimentum fringilla sapien at tempus. Etiam aliquam orci vel tristique imperdiet. Ut ornare ex ac ullamcorper malesuada.`,
    id: 'informal',
    icon: (<UserNinja />),

  },
]
